//@libs
import { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//@routes
import Router from "./routes";
//@hooks
import { useAuth } from "./shared/hooks/useAuth";
//@components
import BackdropLoad from "./shared/components/BackdropLoad";

const App = () => {
  const { user, signOut } = useAuth();

  return (
    <HelmetProvider>
      {signOut.isLoading && <BackdropLoad />}
      <Suspense fallback={<BackdropLoad />}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Router user={user} />
        </LocalizationProvider>
      </Suspense>
    </HelmetProvider>
  );
};

export default App;
