//@libs
import { lazy } from "react";
//@services
import ConstantsService from "../../services/constants.service";
//@pages
const Units = lazy(() => import("./pages/units"));
//@components
const ErrorPage = lazy(() => import("../../shared/components/ErrorPage"));
const Can = lazy(() => import("../../shared/components/Can"));

const unitRoutes = [
  {
    path: "/administration/units",
    element: (
      <Can
        availableTo={[ConstantsService.ROLES.ADMINISTRATOR]}
        yes={() => <Units />}
        no={() => <ErrorPage code={401} />}
      />
    ),
  },
];

export default unitRoutes;
