//@libs
import { lazy } from "react";
//@services
import ConstantsService from "../../services/constants.service";
//@pages
const Users = lazy(() => import("./pages/users"));
//@components
const ErrorPage = lazy(() => import("../../shared/components/ErrorPage"));
const Can = lazy(() => import("../../shared/components/Can"));

const userRoutes = [
  {
    path: "/administration/users",
    element: (
      <Can
        availableTo={[ConstantsService.ROLES.ADMINISTRATOR]}
        yes={() => <Users />}
        no={() => <ErrorPage code={401} />}
      />
    ),
  },
];

export default userRoutes;
