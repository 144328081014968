export default function DataTable(theme) {
  return {
    MUIDataTable: {
      styleOverrides: {
        paper: {
          boxShadow: "none",
          borderRadius: theme.spacing(3),
        },
        responsiveBase: {
          borderRadius: theme.spacing(2),
        },
      },
    },
    MUIDataTableToolbarSelect: {
      styleOverrides: {
        root: {
          minHeight: 64,
          padding: 8,
          boxShadow: "none",
        },
        title: {
          paddingLeft: 0,
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          font: "normal normal 700 12px/16px Inter",
          backgroundColor: "#F5F7FF"
        },
        sortAction: {
          color: theme.palette.primary.main,
          font: "normal normal 700 12px/16px Inter",
        },
        toolButton: {
          color: theme.palette.primary.main,
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        headerCell: {
          backgroundColor: "#F5F7FF",
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          font: "normal normal 400 14px/16px Inter",
          color: "#494B5D",
        },
      },
    },
    MUIDataTablePagination: {
      styleOverrides: {
        navContainer: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        tableCellContainer: {
          borderBottom: "none",
        },
      },
    },
  };
}
