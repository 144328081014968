//@libs
import { lazy } from "react";
import { Navigate } from "react-router-dom";
//@Layout
import AuthLayout from "./components/Layout";
//@services
import LocalStorageService from "../../services/localStorage.service";
//@pages
const SignIn = lazy(() => import("./pages/sign-in"));
const ForgotPassword = lazy(() => import("./pages/forgot-password"));
const ResetPassword = lazy(() => import("./pages/reset-password"));
const SignUp = lazy(() => import("./pages/sign-up"));

const authRoutes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      ...(LocalStorageService.get("registerUser")
        ? [
            { path: "/", element: <Navigate to="/auth/sign-up" /> },
            { path: "*", element: <Navigate to="/auth/sign-up" /> },
            {
              path: "/auth/sign-up",
              element: <SignUp />,
            },
          ]
        : [
            { path: "/", element: <Navigate to="/auth/sign-in" /> },
            { path: "*", element: <Navigate to="/auth/sign-in" /> },
            { path: "/auth/sign-in", element: <SignIn /> },
            { path: "/auth/forgot-password", element: <ForgotPassword /> },
            { path: "/auth/reset-password", element: <ResetPassword /> },
          ]),
    ],
  },
];

export default authRoutes;
