//@libs
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
//@theme
import ThemeProvider from "./theme";
import GlobalStyles from "./theme/GlobalStyles";
//@components
import App from "./App";
//@hooks
import { ProvideAuth } from "./shared/hooks/useAuth";
import "react-toastify/dist/ReactToastify.css";
//@fonts
import "@fontsource/inter";
import "moment/locale/es";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 0,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <GlobalStyles />
      <ToastContainer />
      <BrowserRouter>
        <ProvideAuth>
          <App />
        </ProvideAuth>
      </BrowserRouter>
    </ThemeProvider>
  </QueryClientProvider>
);
