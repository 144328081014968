import { alpha } from "@mui/material/styles";
const createGradient = (color1, color2) => {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
};

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const PRIMARY = {
  100: "#EAECFE",
  lighter: "#F5F7FF",
  light: "#A3A9D3",
  400: "#8187B9",
  500: "#5F66A2",
  main: "#1B1E35",
  dark: "#424983",
  darker: "#262A4F",
  contrastText: "#fff",
};

const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
  contrastText: "#fff",
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#f6ffed",
  light: "#95de64",
  main: "#52c41a",
  dark: "#237804",
  darker: "#092b00",
  contrastText: GREY[800],
};

const WARNING = {
  lighter: "#fffbe6",
  light: "#ffd666",
  main: "#faad14",
  dark: "#ad6800",
  darker: "#613400",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#fff1f0",
  light: "#ff7875",
  main: "#f5222d",
  dark: "#a8071a",
  darker: "#5c0011",
  contrastText: "#fff",
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
  purple: ["#413857", "#665889", "#554971", "#413857"],
  bronze: ["#d58936", "#b97327", "#db9b57", "#e2af78"],
  patients: ["#a53860", "#4c3b4d", "#FFB35C", "#ada8b6", "#61c9a8"],
  services: ["#305252", "#b7d5d4"],
};

const palette = {
  common: { black: "#333333", white: "#fff" },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: {
    black: "#333333",
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: { paper: "#fff", default: GREY[100], neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
