//@libs
import { Outlet } from "react-router-dom";
//@mui-material
import { Box, Grid } from "@mui/material";
//@components
import Logo from "../../../shared/components/Logo";

const AuthLayout = () => {
  return (
    <Grid container sx={{ minHeight: "100vh", padding: 3 }}>
      <Grid
        item
        xs={12}
        md={6}
        lg={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        pt={3}
      >
        <Grid
          container
          spacing={2}
          sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              paddingRight: 6,
            },
          })}
        >
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Logo
              alt="Logo V&Q"
              src="/assets/LOGO-V&Q.png"
              sx={{ transform: "scale(1.5)" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Outlet />
          </Grid>
        </Grid>
        <Box pr={6} sx={{ display: "flex", justifyContent: "center" }}>
          <Logo
            alt="Logo forEach"
            src="/assets/foreach-app.gif"
            sx={{ mt: "auto" }}
            width="120px"
            height="56px"
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        md={6}
        lg={7}
        sx={{
          backgroundImage: "url('/assets/auth-background.svg')",
          backgroundRepeat: "no-repeat",
          opacity: 0.75,
          borderRadius: 4,
          backgroundColor: (theme) => theme.palette.grey[50],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </Grid>
  );
};

export default AuthLayout;
