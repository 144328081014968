import zones from "../shared/data/zones.json";
import countries from "../shared/data/countries.json";
import months from "../shared/data/months.json";
import { Close, Done, EditNote, Block } from "@mui/icons-material";

const ConstantsService = {
  DRAWER_WIDTH: 252,
  DRAWER_MOBILE_WIDTH: 282,
  APPBAR_HEIGHT: 72,
  REQUIRED_MESSAGE: "*Este es un campo requerido.",
  AUTH_KEYS: {
    AUTENTICATED_USER: "authenticatedUser",
    DATA: "data",
    TOKEN: "token",
    REFRESH_TOKEN: "refreshToken",
    REGISTER_USER: "registerUser",
  },
  ROLES: {
    ADMINISTRATOR: "Administrador",
    INTERROGATOR: "Interrogador",
    STUDENT: "Alumno",
  },
  USER_STATUS: {
    ACTIVE: "Activo",
    INACTIVE: "Inactivo",
    PENDING: "Pendiente",
  },
  UNIT_CATEGORY_OPTIONS: [
    "Derecho Civil",
    "Derecho Procesal",
    "Cédula",
    "Evaluación Interna",
    "Otro",
  ],
  UNIT_CATEGORY_STUDENT_DASHBOARD_OPTIONS: [
    "Derecho Civil",
    "Derecho Procesal",
  ],
  UNIT_CATEGORY_TYPES: [
    "Normal",
    "Repaso Parcial",
    "Repaso General",
    "Repaso Final",
  ],
  UNIVERSITIES: [
    "Universidad Católica de Chile",
    "Universidad Católica de Valparaiso",
    "Universidad Academia de Humanismo Cristiano",
    "Universidad Adolfo Ibáñez",
    "Universidad Alberto Hurtado",
    "Universidad Andrés Bello",
    "Universidad Arturo Prat",
    "Universidad Austral de Chile",
    "Universidad Autónoma de Chile",
    "Universidad Bernardo O'higgins",
    "Universidad Bolivariana",
    "Universidad Católica Cardenal Raúl Silva Henríquez",
    "Universidad Católica de Temuco",
    "Universidad Católica Del Maule",
    "Universidad Católica Del Norte",
    "Universidad Central de Chile",
    "Universidad de Antofagasta",
    "UNIACC",
    "Universidad de Atacama",
    "Universidad de Chile",
    "Universidad de Concepción",
    "Universidad de La Frontera",
    "Universidad de La Serena",
    "Universidad de Las Américas",
    "Universidad de Los Andes",
    "Universidad de Los Lagos",
    "Universidad de Magallanes",
    "Universidad de O'higgins",
    "Universidad de Talca",
    "Universidad de Tarapaca",
    "Universidad de Valparaíso",
    "Universidad Viña Del Mar",
    "Universidad Del Desarrollo",
    "Universidad Diego Portales",
    "Universidad Finis Terrae",
    "Universidad La República",
    "Universidad Mayor",
    "Universidad Miguel de Cervantes",
    "Universidad Pedro de Valdivia",
    "Universidad San Sebastián",
    "Universidad Santo Tomás",
    "Universidad de Santiago de Chile",
    "Universidad Gabriela Mistral",
    "Otra (examen escrito)",
    "Otra (examen oral tradicional)",
    "Otra (examen mixto o con casos aplicados)",
  ],
  STUDENT_UNITS_STATUS: {
    APPROVED: {
      label: "Aprobada",
      color: "success",
      bgColor: "#E9FBDF",
      icon: <Done color="success" />,
    },
    PENDING: {
      label: "Por evaluar",
      color: "primary",
      bgColor: "#EAECFE",
      icon: <EditNote color="primary" />,
    },
    FAILED: {
      label: "Reprobada",
      color: "error",
      bgColor: "#FFF1F0",
      icon: <Close color="error" />,
    },
  },
  STUDENT_INTERROGATION_STATUS: {
    APPROVED: {
      label: "Aprobada",
      color: "success",
      bgColor: "#E9FBDF",
      icon: <Done color="success" />,
      value: "APPROVED",
    },
    PENDING: {
      label: "Por evaluar",
      color: "primary",
      bgColor: "#EAECFE",
      icon: <EditNote color="primary" />,
      value: "PENDING",
    },
    FAILED: {
      label: "Reprobada",
      color: "error",
      bgColor: "#FFF1F0",
      icon: <Close color="error" />,
      value: "FAILED",
    },
    SUSPENDED: {
      label: "Suspendida",
      color: "error",
      bgColor: "#FFF1F0",
      icon: <Block color="error" />,
      value: "SUSPENDED",
    },
  },
  REGIONS: zones.map((item) => item.region),
  COMMUNES: (regionName) => {
    return zones.find((item) => item.region === regionName)?.comunas || [];
  },
  COUNTRIES_AREA_CODES: countries,
  MONTHS: months.map((item) => item.spanish),
};

export default ConstantsService;
