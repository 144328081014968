//@mui-material
import { useTheme } from "@mui/material/styles";
import { GlobalStyles } from "@mui/material";

const BaseGlobalStyles = () => {
  const theme = useTheme();
  const swalOverrides = {
    ".swal2-container.swal2-center > .swal2-popup": {
      borderRadius: 24,
    },
    ".swal2-button": {
      margin: theme.spacing(1),
      height: 40,
      boxShadow: theme.shadows[8],
      textTransform: "none",
      borderRadius: 5,
      padding: "10px 20px",
      fontFamily: "Inter",
      fontSize: 14,
      fontWeight: "bold",
      border: `1px solid ${theme.palette.grey[500_32]}`,
      cursor: "pointer",
    },
    ".swal2-button-confirm": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    ".swal2-button-cancel": {
      color: theme.palette.grey[700],
      "&:hover": {
        backgroundColor: theme.palette.action.disabled,
        color: theme.palette.common.white,
      },
    },
    "#swal2-title": {
      lineHeight: "110%",
      fontFamily: "Inter",
      fontSize: 24,
      fontWeight: "bold",
      color: theme.palette.grey[700],
    },
    "#swal2-html-container": {
      padding: 2,
      lineHeight: "110%",
      fontFamily: "Inter",
      fontSize: 20,
      fontWeight: "normal",
      color: theme.palette.grey[600],
    },
  };
  return (
    <GlobalStyles
      styles={{
        ...swalOverrides,
        ".no-border": {
          border: " 0 !important",
        },
      }}
    />
  );
};
export default BaseGlobalStyles;
