//@libs
import { toast } from "react-toastify";

const BASE_CONFIG = {
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "colored",
};
const DEFAULT_MESSAGES = {
  error: "No fue posible realizar la acción",
  success: "Acción Realizada",
  warning: "Advertencia",
  info: "Información",
};

const basicAlert = ({
  type = "success",
  message = "",
  duration = 2500,
  position = "bottom-left",
}) => {
  return toast[type](message || DEFAULT_MESSAGES[type], {
    ...BASE_CONFIG,
    autoClose: duration,
    position,
  });
};

const NotificationService = {
  basicAlert,
};

export default NotificationService;
