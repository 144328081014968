//@libs
import { lazy } from "react";
import { Navigate } from "react-router-dom";
//@routes
import unitRoutes from "../modules/units/routes";
import userRoutes from "../modules/users/routes";
import rubricRoutes from "../modules/rubrics/routes";
import studentRoutes from "../modules/students/routes";
import dashboardRoutes from "../modules/dashboard/routes";
import profileRoutes from "../modules/profile/routes";
import LocalStorageService from "../services/localStorage.service";
import ConstantsService from "../services/constants.service";
//@components
const Layout = lazy(() => import("../shared/components/Layout"));
const NotFound = lazy(() => import("../shared/components/NotFound"));

const { data } = LocalStorageService.get(
  ConstantsService.AUTH_KEYS.AUTENTICATED_USER
);
const getRedirect = () => {
  const isStudent = data?.role === ConstantsService.ROLES.STUDENT;
  if (isStudent) return "/students/home";
  if (data?.role === ConstantsService.ROLES.INTERROGATOR)
    return "/administration/students";
  return "/administration/home";
};

const privateRouter = [
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <Navigate to={getRedirect()} /> },
      { path: "*", element: <NotFound /> },
      { path: "404", element: <NotFound /> },
      ...unitRoutes,
      ...userRoutes,
      ...rubricRoutes,
      ...studentRoutes,
      ...dashboardRoutes,
      ...profileRoutes,
    ],
  },
];

export default privateRouter;
