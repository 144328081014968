//@mui-material
import { Backdrop, Box, CircularProgress } from "@mui/material";
//@components
import Logo from "./Logo";

const BackdropLoad = () => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: 9999,
      }}
      open={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Logo src="/assets/LOGO-V&Q.png" sx={{ transform: "scale(2)" }} />
        <CircularProgress />
      </Box>
    </Backdrop>
  );
};

export default BackdropLoad;
