//@libs
import { useState, useContext, createContext } from "react";
import { useMutation } from "@tanstack/react-query";
//@services
import AuthService from "../../services/auth.service";
import NotificationService from "../../services/notifications.service";
import LocalStorageService from "../../services/localStorage.service";
import ConstantsService from "../../services/constants.service";
//@utilities
import { messageAlert } from "../../utils/alerts";

const fakeAwait = async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));
};

const logOut = async () => {
  await fakeAwait();
};

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = () => {
  const onSuccess = (options) => {
    const { title, message, icon } = options;
    messageAlert(title, message, {
      icon: icon,
    });
  };
  const onError = () =>
    NotificationService.basicAlert({
      type: "error",
      message: "Credenciales incorrectas",
    });

  const [user, setUser] = useState(() => {
    const authenticatedUser = LocalStorageService.get(
      ConstantsService.AUTH_KEYS.AUTENTICATED_USER
    );
    if (authenticatedUser) {
      return authenticatedUser;
    }
    return null;
  });

  const login = useMutation(AuthService.signIn, {
    onSuccess: (authenticatedUser) => {
      if (
        authenticatedUser.data.status === ConstantsService.USER_STATUS.PENDING
      ) {
        LocalStorageService.save(
          ConstantsService.AUTH_KEYS.REGISTER_USER,
          authenticatedUser
        );
        window.location.reload("/auth/sign-up");
      } else {
        LocalStorageService.save(
          ConstantsService.AUTH_KEYS.AUTENTICATED_USER,
          authenticatedUser
        );
        if (
          authenticatedUser.data.role === ConstantsService.ROLES.ADMINISTRATOR
        )
          window.location.replace("/administration/home");
        else if (
          authenticatedUser.data.role === ConstantsService.ROLES.INTERROGATOR
        )
          window.location.replace("/interrogators/home");
        else window.location.replace("/students/home");
      }
    },
    onError: (err) => {
      console.log(err);
      NotificationService.basicAlert({
        type: "error",
        message: "Credenciales incorrectas",
      });
    },
  });
  const signUp = useMutation(AuthService.signUp, {
    onSuccess: async (registerUser) => {
      await messageAlert(
        "Registro completado",
        `¡${registerUser?.name} tú registro esta completo, ya puedes iniciar sesión!`,
        {
          icon: "success",
        }
      ).then(() => {
        LocalStorageService.remove(ConstantsService.AUTH_KEYS.REGISTER_USER);
        window.location.replace("/auth/sign-in");
      });
    },
    onError: (err) => {
      NotificationService.basicAlert({
        type: "error",
        message: "No fue posible completar el registro",
      });
    },
  });
  const signOut = useMutation(logOut, {
    onSuccess: () => {
      LocalStorageService.remove(ConstantsService.AUTH_KEYS.AUTENTICATED_USER);
      setUser(null);
    },
  });
  const recoveryPass = useMutation(AuthService.forgotPassword, {
    onError,
  });
  const resetPass = useMutation(AuthService.resetPassword, {
    onError: async (err) => {
      if (err?.response?.data?.message === "jwt expired") {
        return err;
      }
      onSuccess({
        title: "Error",
        message: "No fue posible realizar el cambio de contraseña",
        icon: "error",
      });
    },
  });
  const updateCurrentUser = (userUpdated) => {
    setUser((prevUser) => ({
      ...prevUser,
      data: userUpdated,
    }));
    LocalStorageService.save(ConstantsService.AUTH_KEYS.AUTENTICATED_USER, {
      ...user,
      data: userUpdated,
    });
  };
  return {
    user,
    login,
    recoveryPass,
    resetPass,
    signOut,
    signUp,
    updateCurrentUser,
  };
};
