import { AxiosInterceptor } from "../interceptors";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

AxiosInterceptor(axiosInstance);

export default axiosInstance;
