//@libs
import { lazy } from "react";
//@services
import ConstantsService from "../../services/constants.service";
//@pages
const Profile = lazy(() => import("./pages/profile"));
//@components
const ErrorPage = lazy(() => import("../../shared/components/ErrorPage"));
const Can = lazy(() => import("../../shared/components/Can"));

const profileRoutes = [
  {
    path: "/profile",
    element: (
      <Can
        availableTo={Object.values(ConstantsService.ROLES)}
        yes={() => <Profile />}
        no={() => <ErrorPage code={401} />}
      />
    ),
  },
];

export default profileRoutes;
