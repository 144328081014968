//@libs
import PropTypes from "prop-types";
import { memo } from "react";
//@mui-material
import Box from "@mui/material/Box";

const Logo = ({ name = "", src = "", ...props }) => (
  <Box component="img" src={src} {...props} width={200} />
);

Logo.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string,
};

export default memo(Logo);
