//@libs
import { lazy } from "react";
//@services
import ConstantsService from "../../services/constants.service";
//@pages
const AdminDashboard = lazy(() => import("./pages/admin-dashboard"));
const StudentDashboard = lazy(() => import("./pages/student-dashboard"));
const InterrogatorDashboard = lazy(() => import("./pages/interrogator-dashboard"));
//@components
const ErrorPage = lazy(() => import("../../shared/components/ErrorPage"));
const Can = lazy(() => import("../../shared/components/Can"));

const dashboardRoutes = [
  {
    path: "/administration/home",
    element: (
      <Can
        availableTo={[ConstantsService.ROLES.ADMINISTRATOR]}
        yes={() => <AdminDashboard />}
        no={() => <ErrorPage code={401} />}
      />
    ),
  },
  {
    path: "/students/home",
    element: (
      <Can
        availableTo={[ConstantsService.ROLES.STUDENT]}
        yes={() => <StudentDashboard />}
        no={() => <ErrorPage code={401} />}
      />
    ),
  },
  {
    path: "/interrogators/home",
    element: (
      <Can
        availableTo={[ConstantsService.ROLES.INTERROGATOR]}
        yes={() => <InterrogatorDashboard />}
        no={() => <ErrorPage code={401} />}
      />
    ),
  },
];

export default dashboardRoutes;
