const save = (key, value) => {
  let currentItem = localStorage.getItem(key) || {};
  currentItem = typeof currentItem === "string" && JSON.parse(currentItem);
  const newItem = {
    ...currentItem,
    ...value,
  };
  localStorage.setItem(key, JSON.stringify(newItem));
};
const get = (key) => {
  const result = localStorage.getItem(key);
  return !!result && JSON.parse(result);
};
const remove = (key) => {
  localStorage.removeItem(key);
};

const LocalStorageService = {
  save,
  get,
  remove,
};

export default LocalStorageService;
