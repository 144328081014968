//@libs
import { lazy } from "react";
//@services
import ConstantsService from "../../services/constants.service";
//@pages
const Rubrics = lazy(() => import("./pages/rubrics"));
const NewRubric = lazy(() => import("./pages/new-rubric"));
const Rubric = lazy(() => import("./pages/rubric"));
//@components
const ErrorPage = lazy(() => import("../../shared/components/ErrorPage"));
const Can = lazy(() => import("../../shared/components/Can"));

const rubricsRoutes = [
  {
    path: "/administration/rubrics",
    element: (
      <Can
        availableTo={[ConstantsService.ROLES.ADMINISTRATOR]}
        yes={() => <Rubrics />}
        no={() => <ErrorPage code={401} />}
      />
    ),
  },
  {
    path: "/administration/rubrics/new-rubric",
    element: (
      <Can
        availableTo={[ConstantsService.ROLES.ADMINISTRATOR]}
        yes={() => <NewRubric />}
        no={() => <ErrorPage code={401} />}
      />
    ),
  },
  {
    path: "/administration/rubrics/:rubricId",
    element: (
      <Can
        availableTo={[ConstantsService.ROLES.ADMINISTRATOR]}
        yes={() => <Rubric />}
        no={() => <ErrorPage code={401} />}
      />
    ),
  },
];

export default rubricsRoutes;
