//@libs
import { lazy } from "react";
//@services
import ConstantsService from "../../services/constants.service";
//@pages
const Students = lazy(() => import("./pages/students"));
const Student = lazy(() => import("./pages/student"));
const StudentUnits = lazy(() => import("./pages/units"));
const StudentUnitDetails = lazy(() => import("./pages/unit-details"));
const StudentInterrogation = lazy(() => import("./pages/interrogation"));
//@components
const ErrorPage = lazy(() => import("../../shared/components/ErrorPage"));
const Can = lazy(() => import("../../shared/components/Can"));

const studentRoutes = [
  {
    path: "/administration/students",
    element: (
      <Can
        availableTo={[
          ConstantsService.ROLES.ADMINISTRATOR,
          ConstantsService.ROLES.INTERROGATOR,
        ]}
        yes={() => <Students />}
        no={() => <ErrorPage code={401} />}
      />
    ),
  },
  {
    path: "/administration/students/:studentId",
    element: (
      <Can
        availableTo={[
          ConstantsService.ROLES.ADMINISTRATOR,
          ConstantsService.ROLES.INTERROGATOR,
        ]}
        yes={() => <Student />}
        no={() => <ErrorPage code={401} />}
      />
    ),
  },
  {
    path: "/administration/students/:studentId/interrogations/:interrogationId",
    element: (
      <Can
        availableTo={[
          ConstantsService.ROLES.ADMINISTRATOR,
          ConstantsService.ROLES.INTERROGATOR,
        ]}
        yes={() => <StudentInterrogation />}
        no={() => <ErrorPage code={401} />}
      />
    ),
  },
  {
    path: "/students/units",
    element: (
      <Can
        availableTo={[ConstantsService.ROLES.STUDENT]}
        yes={() => <StudentUnits />}
        no={() => <ErrorPage code={401} />}
      />
    ),
  },
  {
    path: "/students/units/:unitId/interrogations",
    element: (
      <Can
        availableTo={[ConstantsService.ROLES.STUDENT]}
        yes={() => <StudentUnitDetails />}
        no={() => <ErrorPage code={401} />}
      />
    ),
  },
];

export default studentRoutes;
