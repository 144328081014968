function pxToRem(value) {
  return `${value / 16}rem`;
}

const FONT_PRIMARY = "Inter, sans-serif";

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 400,
    fontSize: pxToRem(38),
    lineHeight: "46px",
  },
  h2: {
    fontWeight: 400,
    fontSize: pxToRem(30),
    lineHeight: "38px",
  },
  h3: {
    fontWeight: 400,
    fontSize: pxToRem(24),
    lineHeight: "32px",
  },
  h4: {
    fontWeight: 400,
    fontSize: pxToRem(20),
    lineHeight: "28px",
  },
  h5: {
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: "24px",
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: "22px",
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: "20px",
  },
  caption: {
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: "20px",
  },
  subheading: {
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: "22px",
  },
};

export default typography;
